import React from 'react';
import loadable from '@loadable/component';

import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';

const PartnerWithUsComponent = loadable(() => import('../components/partner-with-us/partner-with-us-main'))
const Layout = loadable(() => import('../components/Layout'))
const Head = loadable(() => import('../components/Head'))


const PartnerWithUS = () => {

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Head title="Partner" />
                <PartnerWithUsComponent />
                
            </Layout>
        </ThemeProvider>
    );
};

export default PartnerWithUS;
